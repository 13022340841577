import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import StyledHero from "../components/styledHero"
import Join from "../components/forms/join"
import SEO from "../components/seo"

export default function join({ data, location }) {
  return (
    <Layout location={location.pathname}>
      <SEO title="Mitmachen" />
      <StyledHero img={data.defaultBcg.childImageSharp.fluid} />
      <Join />
    </Layout>
  )
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
