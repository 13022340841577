import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/form.module.css"
import Axios from "axios"

export default class JoinFormComponent extends React.Component {
  state = {
    role: { value: "vendor", error: false, message: "" },
    name: { value: "", error: false, message: "" },
    contact_address1: { value: "", error: false, message: "" },
    contact_postal: { value: "", error: false, message: "" },
    contact_city: { value: "", error: false, message: "" },
    contact_country: { value: "DE", error: false, message: "" },
    contact_name: { value: "", error: false, message: "" },
    contact_person_given_name: { value: "", error: false, message: "" },
    contact_phone_landline: { value: "", error: false, message: "" },
    contact_email: { value: "", error: false, message: "" },
    offer: { value: "", error: false, message: "" },
    capacity: { value: "", error: false, message: "" },
    restrictions: { value: "", error: false, message: "" },
    comments: { value: "", error: false, message: "" },
    compensation: { value: "probono", error: false, message: "" },
    vscovidunited_fund_interested: { value: "0", error: false, message: "" },
    submitActive: false,
    globalFormError: "", //Non-validation error during submit (e.g. backend unreachable)
    success: false,
  }
  statics = {
    formFields: [
      "role",
      "name",
      "contact_address1",
      "contact_postal",
      "contact_city",
      "contact_country",
      "contact_name",
      "contact_person_given_name",
      "contact_phone_landline",
      "contact_email",
      "offer",
      "capacity",
      "restrictions",
      "comments",
      "compensation",
      "vscovidunited_fund_interested",
    ],
  }
  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    let stateVal = this.state[name]
    stateVal.value = value
    this.setState({
      [name]: stateVal,
    })
  }
  handleSubmit = (event) => {
    event.preventDefault()
    let submitObj = {}
    submitObj["_method"] = "PUT" // If symfony doesn't get this, it barfs
    submitObj["_locale"] = "de_DE" // For now hardcoded as long as we're german only
    //Reset all errors prior to submission
    this.setState({
      globalFormError: "",
    })
    this.statics.formFields.forEach((key) => {
      let stateVal = this.state[key]
      stateVal.message = ""
      stateVal.error = false
      this.setState({
        [key]: stateVal,
      })
      submitObj[key] = stateVal.value
    })
    //disable the submit button
    this.setState({ submitActive: true })
    Axios.post(event.target.getAttribute("action"), submitObj)
      .then((response) => {
        this.setState({ success: true })
      })
      .catch((error) => {
        let response = error.response
        if (!response.data || !response.data.error_code) {
          //Grave error, we don't even have an error code
          this.setState({
            globalFormError:
              "Unbekannter Fehler bei der Übertragung, bitte versuchen Sie es später noch einmal.",
          })
          return
        }
        if (response.data.error_code === "general") {
          //Grave error, but somewhere in symfony land. We can show a message if we have one
          if (response.data.message) {
            this.setState({
              globalFormError:
                "Fehler bei der Übertragung (" +
                response.data.message +
                "), bitte versuchen Sie es später noch einmal.",
            })
          } else {
            this.setState({
              globalFormError:
                "Unbekannter Fehler bei der Übertragung, bitte versuchen Sie es später noch einmal.",
            })
          }
          return
        }
        if (response.data.error_code === "error_validation") {
          //Some field(s) failed to validate
          response.data.data.forEach((validationError) => {
            let stateVal = this.state[validationError.name]
            stateVal.message = validationError.message
            stateVal.error = true
            this.setState({
              [validationError.name]: stateVal,
            })
          })
          this.setState({
            globalFormError:
              "Einige Angaben konnten nicht validiert werden, bitte korrigieren Sie diese (in rot markiert).",
          })
          return
        }
        //Catch all
        if (response.data.message) {
          this.setState({
            globalFormError:
              "Fehler bei der Übertragung (" +
              response.data.error_code +
              ", " +
              response.data.message +
              "), bitte versuchen Sie es später noch einmal.",
          })
        } else {
          this.setState({
            globalFormError:
              "Unbekannter Fehler bei der Übertragung, bitte versuchen Sie es später noch einmal.",
          })
        }
      })
      .finally(() => {
        this.setState({ submitActive: false })
      })
  }

  render() {
    return (
      <section className={styles.formContainer}>
        <div style={{ display: this.state.success ? "none" : "block" }}>
          <Title title="Hier" subtitle="Anmelden" />
          <div className={styles.center}>
            <p>
              Wenn Sie die Initiative #VsCovidUnited unterstützen wollen,
              dringend Hilfe benötigen oder Hilfe anbieten wollen, dann melden
              Sie sich bei uns möglichst schnell und spezifisch an.
            </p>
            <form
              action={`${process.env.API_BASEURL}/form/submit`}
              method="POST"
              onSubmit={this.handleSubmit}
              className={styles.form}
            >
              <input type={"hidden"} name={"_method"} value={"PUT"} />
              <h4>In welcher Rolle möchte ich teilnehmen?</h4>
              <div>
                <select
                  id="role"
                  name="role"
                  className={
                    styles.formControl + (this.state.role.error ? " error" : "")
                  }
                  required
                  value={this.state.role.value}
                  onChange={this.handleInputChange}
                >
                  <option value="vendor">Anbieter</option>
                  <option value="requestor">Nachfrager</option>
                  <option value="investor">Investor</option>
                  <option value="employee">Mitarbeiter</option>
                  <option value="sponsor">Sponsor</option>
                  <option value="mentor">Botschafter/Mentor</option>
                </select>
                <div
                  className={styles.formElementError}
                  style={{ display: this.state.role.error ? "block" : "none" }}
                >
                  {this.state.role.message}
                </div>
              </div>
              <h4>Wer sind Sie?</h4>
              <div>
                <label htmlFor="company">
                  Name des Unternehmens / der Institution / des Selbständigen*
                </label>
                <input
                  type="text"
                  name="name"
                  id="company"
                  className={
                    styles.formControl + (this.state.name.error ? " error" : "")
                  }
                  placeholder="Musterunternehmen"
                  autoComplete={"organization"}
                  required
                  value={this.state.name.value}
                  onChange={this.handleInputChange}
                />
                <div
                  className={styles.formElementError}
                  style={{ display: this.state.name.error ? "block" : "none" }}
                >
                  {this.state.name.message}
                </div>
              </div>
              <div>
                <label htmlFor="contact_address1">Strasse / Hausnummer*</label>
                <input
                  type="text"
                  name="contact_address1"
                  id="contact_address1"
                  className={
                    styles.formControl +
                    (this.state.contact_address1.error ? " error" : "")
                  }
                  placeholder="Musterstraße 1"
                  required
                  autoComplete={"address-line1"}
                  onChange={this.handleInputChange}
                  value={this.state.contact_address1.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.contact_address1.error
                      ? "block"
                      : "none",
                  }}
                >
                  {this.state.contact_address1.message}
                </div>
              </div>
              <div>
                <label htmlFor="postal">PLZ*</label>
                <input
                  type="text"
                  name="contact_postal"
                  id="postal"
                  className={
                    styles.formControl +
                    (this.state.contact_postal.error ? " error" : "")
                  }
                  placeholder="12345"
                  required
                  autoComplete={"postal-code"}
                  onChange={this.handleInputChange}
                  value={this.state.contact_postal.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.contact_postal.error ? "block" : "none",
                  }}
                >
                  {this.state.contact_postal.message}
                </div>
                <label htmlFor="city">Ort*</label>
                <input
                  type="text"
                  name="contact_city"
                  id="city"
                  className={
                    styles.formControl +
                    (this.state.contact_city.error ? " error" : "")
                  }
                  placeholder="Musterstadt"
                  autoComplete={"address-level2"}
                  required
                  onChange={this.handleInputChange}
                  value={this.state.contact_city.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.contact_city.error ? "block" : "none",
                  }}
                >
                  {this.state.contact_city.message}
                </div>
              </div>
              <div>
                <label htmlFor="country">Land*</label>
                <select
                  name="contact_country"
                  id="country"
                  className={
                    styles.formControl +
                    (this.state.contact_country.error ? " error" : "")
                  }
                  onChange={this.handleInputChange}
                  value={this.state.contact_country.value}
                >
                  <option value={"DE"}>Deutschland</option>
                  <option value={"AT"}>Österreich</option>
                  <option value={"CH"}>Schweiz</option>
                </select>
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.contact_country.error
                      ? "block"
                      : "none",
                  }}
                >
                  {this.state.contact_country.message}
                </div>
              </div>
              <div>
                <label htmlFor="name">Name des Ansprechpartners*</label>
                <input
                  type="text"
                  name="contact_name"
                  id="name"
                  className={
                    styles.formControl +
                    (this.state.contact_name.error ? " error" : "")
                  }
                  placeholder="Mustermann"
                  required
                  autoComplete={"family-name"}
                  onChange={this.handleInputChange}
                  value={this.state.contact_name.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.contact_name.error ? "block" : "none",
                  }}
                >
                  {this.state.contact_name.message}
                </div>
              </div>
              <div>
                <label htmlFor="given_name">
                  Vorname des Ansprechpartners*
                </label>
                <input
                  type="text"
                  name="contact_person_given_name"
                  id="given_name"
                  className={
                    styles.formControl +
                    (this.state.contact_person_given_name.error ? " error" : "")
                  }
                  placeholder="Max"
                  required
                  autoComplete={"given-name"}
                  onChange={this.handleInputChange}
                  value={this.state.contact_person_given_name.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.contact_person_given_name.error
                      ? "block"
                      : "none",
                  }}
                >
                  {this.state.contact_person_given_name.message}
                </div>
              </div>
              <div>
                <label htmlFor="phone">Telefonnummer</label>
                <input
                  type="tel"
                  name="contact_phone_landline"
                  id="phone"
                  className={
                    styles.formControl +
                    (this.state.contact_phone_landline.error ? " error" : "")
                  }
                  placeholder="+49 10 7654321"
                  autoComplete={"tel"}
                  onChange={this.handleInputChange}
                  value={this.state.contact_phone_landline.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.contact_phone_landline.error
                      ? "block"
                      : "none",
                  }}
                >
                  {this.state.contact_phone_landline.message}
                </div>
              </div>
              <div>
                <label htmlFor="email">E-Mail*</label>
                <input
                  type="email"
                  name="contact_email"
                  id="email"
                  className={
                    styles.formControl +
                    (this.state.contact_email.error ? " error" : "")
                  }
                  placeholder="email@email.com"
                  required
                  autoComplete={"email"}
                  onChange={this.handleInputChange}
                  value={this.state.contact_email.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.contact_email.error ? "block" : "none",
                  }}
                >
                  {this.state.contact_email.message}
                </div>
              </div>
              <h4
                style={{
                  display: ["vendor", "requestor"].includes(
                    this.state.role.value
                  )
                    ? "block"
                    : "none",
                }}
              >
                <span
                  style={{
                    display:
                      this.state.role.value === "vendor"
                        ? "inline-block"
                        : "none",
                  }}
                >
                  Was biete ich an?
                </span>
                <span
                  style={{
                    display:
                      this.state.role.value === "requestor"
                        ? "inline-block"
                        : "none",
                  }}
                >
                  Was benötige ich?
                </span>
              </h4>
              <div>
                <label htmlFor="offer">
                  <span
                    style={{
                      display:
                        this.state.role.value === "vendor"
                          ? "inline-block"
                          : "none",
                    }}
                  >
                    Was genau ist das Angebot bzw. die angebotene Leistung?
                  </span>
                  <span
                    style={{
                      display:
                        this.state.role.value === "requestor"
                          ? "inline-block"
                          : "none",
                    }}
                  >
                    Was genau ist der Bedarf bzw. die benötigte Leistung?
                  </span>
                  <span
                    style={{
                      display: ["vendor", "requestor"].includes(
                        this.state.role.value
                      )
                        ? "none"
                        : "inline-block",
                    }}
                  >
                    In welcher Form möchte ich VsCovidUnited unterstützen?
                  </span>
                </label>
                <textarea
                  name="offer"
                  id="offer"
                  rows="5"
                  className={
                    styles.formControl +
                    (this.state.offer.error ? " error" : "")
                  }
                  placeholder={
                    this.state.role === "requestor"
                      ? "Ich benötige..."
                      : "Ich biete folgendes an..."
                  }
                  onChange={this.handleInputChange}
                  value={this.state.offer.value}
                />
                <div
                  className={styles.formElementError}
                  style={{ display: this.state.offer.error ? "block" : "none" }}
                >
                  {this.state.offer.message}
                </div>
              </div>
              <div
                style={{
                  display: ["vendor", "requestor"].includes(
                    this.state.role.value
                  )
                    ? "block"
                    : "none",
                }}
              >
                <label htmlFor="capacity">
                  <span
                    style={{
                      display:
                        this.state.role.value === "vendor"
                          ? "inline-block"
                          : "none",
                    }}
                  >
                    Welche Kapazitäten quantitativ und qualitativ werden
                    angeboten?
                  </span>
                  <span
                    style={{
                      display:
                        this.state.role.value === "requestor"
                          ? "inline-block"
                          : "none",
                    }}
                  >
                    Welche Kapazitäten quantitativ und qualitativ werden
                    benötigt?
                  </span>
                </label>
                <textarea
                  name="capacity"
                  id="capacity"
                  rows="3"
                  className={
                    styles.formControl +
                    (this.state.capacity.error ? " error" : "")
                  }
                  placeholder={
                    this.state.role.value === "requestor"
                      ? "Ich habe folgende Kapazitäten..."
                      : "Ich benötige folgende Kapazitäten..."
                  }
                  onChange={this.handleInputChange}
                  value={this.state.capacity.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.capacity.error ? "block" : "none",
                  }}
                >
                  {this.state.capacity.message}
                </div>
              </div>
              <div
                style={{
                  display: ["vendor", "requestor"].includes(
                    this.state.role.value
                  )
                    ? "block"
                    : "none",
                }}
              >
                <label htmlFor="restrictions">
                  Gibt es zeitliche Restriktionen?
                </label>
                <textarea
                  name="restrictions"
                  id="restrictions"
                  rows="3"
                  className={
                    styles.formControl +
                    (this.state.restrictions.error ? " error" : "")
                  }
                  placeholder="Es gelten die folgenden Restriktionen..."
                  onChange={this.handleInputChange}
                  value={this.state.restrictions.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.restrictions.error ? "block" : "none",
                  }}
                >
                  {this.state.restrictions.message}
                </div>
              </div>
              <div
                style={{
                  display: ["vendor", "requestor"].includes(
                    this.state.role.value
                  )
                    ? "block"
                    : "none",
                }}
              >
                <label htmlFor="comments">
                  Was ist aus Ihrer Sicht ansonsten wichtig zu wissen oder zu
                  beachten?
                </label>
                <textarea
                  name="comments"
                  id="comments"
                  rows="3"
                  className={
                    styles.formControl +
                    (this.state.comments.error ? " error" : "")
                  }
                  placeholder="Mich würde folgendes interessieren..."
                  onChange={this.handleInputChange}
                  value={this.state.comments.value}
                />
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.comments.error ? "block" : "none",
                  }}
                >
                  {this.state.comments.message}
                </div>
              </div>
              <div
                style={{
                  display: ["vendor", "requestor"].includes(
                    this.state.role.value
                  )
                    ? "block"
                    : "none",
                }}
              >
                <div className={styles.radioContainer}>
                  <h4>Welche finanziellen Optionen habe ich?</h4>
                  <input
                    type={"radio"}
                    value={"probono"}
                    id={"probono"}
                    name={"compensation"}
                    checked={this.state.compensation.value === "probono"}
                    onChange={this.handleInputChange}
                  />
                  <label
                    htmlFor={"probono"}
                    style={{
                      display:
                        this.state.role.value === "vendor" ? "block" : "none",
                    }}
                  >
                    Mein Angebot gemäß Spezifikation ist pro bono und ich
                    verzichte freiwillig auf jedwede finanzielle Zuwendung oder
                    Kompensation
                  </label>
                  <label
                    htmlFor={"probono"}
                    style={{
                      display:
                        this.state.role.value === "requestor"
                          ? "block"
                          : "none",
                    }}
                  >
                    Mein Hilfegesuch gemäß Spezifikation ist ohne Vergütung für
                    den Anbieter
                  </label>

                  <input
                    type={"radio"}
                    value={"probono_optional"}
                    id={"probono_optional"}
                    name={"compensation"}
                    checked={
                      this.state.compensation.value === "probono_optional"
                    }
                    onChange={this.handleInputChange}
                    style={{
                      display:
                        this.state.role.value === "vendor" ? "block" : "none",
                    }}
                  />
                  <label
                    htmlFor={"probono_optional"}
                    style={{
                      display:
                        this.state.role.value === "vendor" ? "block" : "none",
                    }}
                  >
                    Mein Angebot gemäß Spezifikation ist pro bono, ich nehme
                    aber gerne eine eventuelle Zuwendung oder Kompensation
                    entgegen
                  </label>

                  <input
                    type={"radio"}
                    value={"payment_partial"}
                    id={"payment_partial"}
                    name={"compensation"}
                    checked={
                      this.state.compensation.value === "payment_partial"
                    }
                    onChange={this.handleInputChange}
                    style={{
                      display:
                        this.state.role.value === "requestor"
                          ? "block"
                          : "none",
                    }}
                  />
                  <label
                    htmlFor={"payment_partial"}
                    style={{
                      display:
                        this.state.role.value === "requestor"
                          ? "block"
                          : "none",
                    }}
                  >
                    Mein Hilfegesuch gemäß Spezifikation kann nur teilweise
                    und/oder verzögert vergütet werden (auf Basis bilateraler
                    Einigung)
                  </label>
                  <input
                    type={"radio"}
                    value={"payment_mandatory"}
                    id={"payment_mandatory"}
                    name={"compensation"}
                    checked={
                      this.state.compensation.value === "payment_mandatory"
                    }
                    onChange={this.handleInputChange}
                  />
                  <label
                    htmlFor={"payment_mandatory"}
                    style={{
                      display:
                        this.state.role.value === "vendor" ? "block" : "none",
                    }}
                  >
                    Ich kann meine Leistung nur mit Vergütung anbieten
                  </label>
                  <label
                    htmlFor={"payment_mandatory"}
                    style={{
                      display:
                        this.state.role.value === "requestor"
                          ? "block"
                          : "none",
                    }}
                  >
                    Ich kann die Leistungen zu meinem Hilfegesuch vergüten
                  </label>
                </div>
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.compensation.error ? "block" : "none",
                  }}
                >
                  {this.state.compensation.message}
                </div>
              </div>
              <div
                style={{
                  display: ["vendor", "requestor"].includes(
                    this.state.role.value
                  )
                    ? "block"
                    : "none",
                }}
              >
                <h4>
                  Ich habe Interesse an der Teilnahme am VsCovidUnited Hilfsfond
                </h4>
                <select
                  name={"vscovidunited_fund_interested"}
                  id={"vscovidunited_fund_interested"}
                  className={
                    styles.formControl +
                    (this.state.vscovidunited_fund_interested.error
                      ? " error"
                      : "")
                  }
                  onChange={this.handleInputChange}
                  value={this.state.vscovidunited_fund_interested.value}
                >
                  <option value={"1"}>Ja</option>
                  <option value={"0"}>Nein</option>
                </select>
                <div
                  className={styles.formElementError}
                  style={{
                    display: this.state.vscovidunited_fund_interested.error
                      ? "block"
                      : "none",
                  }}
                >
                  {this.state.vscovidunited_fund_interested.message}
                </div>
              </div>
              <div className={styles.formGlobalError}>
                {this.state.globalFormError}
              </div>
              <p>
                Wenn Sie die im Kontaktformular eingegebenen Daten durch Klick
                auf den nachfolgenden Button übersenden, erklären Sie sich mit
                der{" "}
                <AniLink fade to="/privacy">
                  Datenschutzerklärung
                </AniLink>{" "}
                auf dieser Website einverstanden.
              </p>
              <div>
                <input
                  type="submit"
                  value="Absenden"
                  className={styles.submit}
                  disabled={this.state.submitActive}
                />
              </div>
            </form>
          </div>
        </div>
        <div style={{ display: this.state.success ? "block" : "none" }}>
          <Title title="Vielen Dank!" />
          <div className={styles.center}>
            <p>
              Ihre Anmeldung ist bei uns eingegangen. Sie erhalten von uns in
              Kürze eine E-Mail; bitte bestätigen Sie aus Datenschutzgründen
              Ihre Anmeldung mit dem enthaltenen Link.
            </p>
          </div>
        </div>
      </section>
    )
  }
}
